var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"resource-item-wrap",attrs:{"element-loading-spinner":"el-icon-loading"}},[_c('div',{staticClass:"resource-item"},[_c('div',{staticClass:"resource-item__header",class:{ 'show-border': _vm.showContent },on:{"click":_vm.getResourceDetail}},[_c('div',{staticClass:"left"},[_c('img',{staticClass:"filetype",attrs:{"src":require(("@/assets/images/" + (_vm.item.fileType) + ".png"))}}),_c('h1',{staticClass:"resource-title",domProps:{"innerHTML":_vm._s(
            (_vm.data.highs && _vm.data.highs.filename && _vm.data.highs.filename[0]) ||
              _vm.item.filename
          )}})]),_c('div',{staticClass:"other-info"},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm.item.utime.substr(0, 10)))])])]),(
        _vm.item.filelist &&
          _vm.item.filelist.length > 0 &&
          _vm.item.isdir === 1 &&
          _vm.showContent
      )?_c('div',{staticClass:"resource-detail-wrap"},[_vm._l((_vm.item.filelist),function(detailItem,detailIndex){return _c('p',{key:("detail" + detailIndex),staticClass:"detail-item-wrap"},[_c('span',{staticClass:"detail-item-title",domProps:{"innerHTML":_vm._s(
            (_vm.data.highs &&
              _vm.data.highs['filelist.filename'] &&
              _vm.data.highs['filelist.filename'][detailIndex]) ||
              detailItem.filename
          )}}),(detailItem.isdir !== 1)?_c('span',[_vm._v(" - "+_vm._s(_vm._f("bytesToSize")(detailItem.size)))]):_vm._e()])}),(_vm.item.filelist.length > 5)?_c('p',{staticClass:"detail-item-wrap"},[_c('span',{},[_vm._v("......")])]):_vm._e()],2):_vm._e(),(_vm.showContent)?_c('div',{staticClass:"resource-meta"},[_c('div',{staticClass:"left"},[_c('span',{staticClass:"meta-item"},[_vm._v(" 文件共计："+_vm._s(_vm._f("bytesToSize")(_vm.item.size))+" ")]),(_vm.item.type)?_c('span',{staticClass:"meta-item image-wrap"},[_c('img',{attrs:{"src":("https://res.yimiaopan.com/pan/icons/" + (_vm.item.type) + ".png")}})]):_vm._e(),_c('a',{staticClass:"report-button danger",attrs:{"href":("http://report.3453k.cn?fid=" + _vm.id + "&type=" + _vm.type),"target":"_blank"}},[_vm._v("举报资源")])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"check-wrap"},[(_vm.isCheckingUrlValid)?_c('p',{staticClass:"checking-tip"},[_vm._v(" 正在检查该链接是否有效... ")]):_vm._e(),(!_vm.isCheckingUrlValid)?_c('p',{staticClass:"result-tip"},[(_vm.valid == 1)?_c('span',{staticClass:"valid"},[_vm._v("该链接有效，可以访问")]):_vm._e(),(_vm.valid == -1)?_c('span',{staticClass:"invalid"},[_vm._v("该链接已经失效，无法访问")]):_vm._e()]):_vm._e()]),(_vm.pwd)?_c('span',{staticClass:"meta-item copy-item",on:{"click":function($event){$event.stopPropagation();return _vm.copy(_vm.pwd)}}},[_vm._v(" 提取码:"+_vm._s(_vm.pwd)+" "),_c('span',{staticClass:"copy"},[_vm._v("点击复制")])]):_vm._e(),_c('van-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.openUrl}},[_vm._v("前往网盘下载")])],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }