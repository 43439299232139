<template>
  <div class="result-page">
    <filter-component
      :keyword="keyword"
      :size="size"
      :type="type"
      :time="time"
      :searchtype="searchtype"
      :restype="restype"
    />
    <div class="search-result" v-if="keyword">
      <template v-if="!loading">
        <div class="result-inner" v-if="results.length > 0">
          <p class="search-tip">
            <span
              >共搜索出 <span class="em">{{ total }}</span> 条结果</span
            >
          </p>
          <div class="result-wrap">
            <resource-item
              v-for="(item, index) in results"
              :data="item"
              :keyword="keyword"
              @login="showLogin"
              :key="`search-${item.res.filename}-${index}`"
            />
          </div>
          <pager
            :pageCurrent="page"
            :pageTotal="`${Math.ceil(total / 30)}`"
            :keyword="keyword"
          />
        </div>
        <div class="sensitive" v-if="data === 'toomuch' || data === 'limited'">
          <img
            src="@/assets/images/error.png"
            class="none-image"
            alt="您的搜索过于频繁，建议稍后尝试！"
          />
          <p class="none-tip">您的搜索过于频繁，建议稍后尝试！</p>
          <p>
            访问
            <a href="https://www.ip138.com" target="_blank">www.ip138.com</a>
            ，把 IP 地址提供给客服（QQ：3556044645）<br />客服会在12小时解除封禁
          </p>
        </div>

        <div class="sensitive" v-if="data === 'unionid_limited'">
          <img
            src="@/assets/images/error.png"
            class="none-image"
            alt="您的搜索过于频繁，建议稍后尝试！"
          />
          <p class="none-tip">当前用户搜索过于频繁，建议稍后尝试！</p>
          <p>
            右上角完整的用户id复制给客服（QQ：3556044645）客服会在12小时解除封禁
          </p>
        </div>

        <div class="sensitive" v-if="data === 'blocked'">
          <img
            src="@/assets/images/error.png"
            class="none-image"
            alt="当前IP禁止访问"
          />
          <p class="none-tip">当前IP禁止访问，如需解封，请如下操作：</p>
          <p>
            访问
            <a href="https://www.ip138.com" target="_blank">www.ip138.com</a>
            ，把 IP 地址提供给客服（QQ：3556044645）<br />客服会在12小时解除封禁
          </p>
        </div>

        <div
          class="sensitive"
          v-if="
            data === 'politics' ||
              data === 'porn' ||
              data === 'contraband' ||
              data === 'terrorism' ||
              data === 'privacy'
          "
        >
          <p class="none-tip">
            你搜索的
            <span class="em">『{{ keyword }}』</span> 包含违禁词，请文明搜索！
          </p>
        </div>

        <div class="sensitive" v-if="isSpecified(data)">
          <img src="@/assets/images/error.png" class="none-image" />
          <p class="none-tip">{{ data }}</p>
          <p>如有误判，可以联系客服，QQ：3556044645</p>

          <van-button
            v-if="userinfo && data.includes('频次')"
            type="primary"
            style="margin-top: 20px; width: 150px;"
            @click="handleApplyUnblock"
            >申请临时解封</van-button
          >
          <van-button
            v-if="
              userinfo && data.includes('免费用户') && data.includes('搜索次数')
            "
            type="primary"
            style="margin-top: 20px; width: 200px;"
            @click="goPayPage"
            >捐赠会员无视限制</van-button
          >
        </div>

        <div class="sensitive" v-if="results.length == 0 && !data">
          <img src="@/assets/images/error.png" class="none-image" />
          <p class="none-tip" v-if="results.length == 0 && keyword">
            服务器线路忙，请稍后重试！
          </p>
          <p>
            访问
            <a href="https://www.ip138.com" target="_blank">www.ip138.com</a>
            ，把 IP 地址提供给客服（QQ：3556044645）<br />客服会在12小时解除封禁
          </p>
        </div>

        <div
          class="sensitive"
          v-if="results.length == 0 && data && data.resources === null"
        >
          <img src="@/assets/images/error.png" class="none-image" />
          <p class="none-tip" v-if="results.length == 0 && keyword">
            没有搜到 <span class="em">『{{ keyword }}』</span> 的任何结果
            <span v-if="searchtype != 1">，切换 `『模糊搜索』` 搜一搜</span>
            。
          </p>
          <p class="none-tip" v-if="results.length == 0 && !keyword">
            关键词为空，请输入关键词进行搜索。
          </p>
        </div>
      </template>
      <div class="loading-wrap" v-if="loading">
        <lottie-player
          class="animation"
          src="https://res.yimiaopan.com/pan/lottie/search.json"
          background="transparent"
          speed="0.4"
          loop
          autoplay
        />
        <p>资源搜索中...</p>
      </div>
    </div>
    <div v-if="!keyword" class="search-tip-wrap">
      <h4>搜索小技巧：</h4>
      <p class="search-tip">1、可以灵活选用「精准搜索」和「模糊搜索」</p>
      <p class="search-tip">
        2、搜索资源时可以通过资源「类型」、「时间」等进行筛选
      </p>
      <p class="search-tip">
        3、增加「文件来源」如（<span class="em">百度网盘</span>、<span
          class="em"
          >阿里网盘</span
        >等）进行筛选
      </p>
      <p class="search-tip">
        4、搜索关键次尽量不要包含「的」「与」等无关助词，只包含关键词即可
      </p>
      <p class="search-tip">
        5、如搜索过于频繁被屏蔽，请发送UID信息给shakanamo945@gmail.com申请解封
      </p>
      <p class="search-tip">
        6、相关版权方对于搜索关键词屏蔽需求请发送邮件至：shakanamo945@gmail.com；
      </p>
      <p class="search-tip em">
        7、坚决抵制劣质、违规、隐私、风险版权、其他问题资源，请大家发现一律通过页面举报；
      </p>
    </div>
  </div>
</template>

<script>
import Pager from "../components/PagerNew.vue";
import FilterComponent from "../components/Filter.vue";
import ResourceItem from "../components/ResourceItem.vue";
import { getSearchResult } from "../api/search";
import { applyUnblock } from "../api/user";
import getFileType from "../utils/getFileType";
import { FILTER } from "../constants";

export default {
  data() {
    return {
      url: "",
      data: null,
      size: -1,
      type: -1,
      time: -1,
      searchtype: -1,
      restype: -1,
      page: 1,
      total: 0,
      results: [],
      keyword: "",
      loading: true,
      FILTER,
    };
  },
  watch: {
    $route: function(to) {
      this.setRoute(to.query);
      this.refresh();
      if (this.$refs.page) {
        this.$refs.page.scrollTop = 0;
      }
    },
  },
  async mounted() {
    this.setRoute(this.$route.query);
    await this.refresh();
  },
  methods: {
    isSpecified(data) {
      return (
        data &&
        typeof data === "string" &&
        ![
          "toomuch",
          "limited",
          "unionid_limited",
          "blocked",
          "politics",
          "porn",
          "contraband",
          "terrorism",
          "privacy",
        ].includes(data) &&
        !data.startsWith("blocked")
      );
    },
    setRoute(query) {
      const {
        kw,
        keyword,
        page = 1,
        size,
        type,
        time,
        searchtype,
        restype,
      } = query;
      this.size = size;
      this.type = type;
      this.time = time;
      this.page = parseInt(page);
      this.keyword = kw || keyword;
      this.searchtype = searchtype;
      this.restype = restype;
    },
    async refresh() {
      this.loading = true;
      let data = null;
      data = await getSearchResult({
        page: this.page,
        size: this.size,
        type: this.type,
        time: this.time,
        keyword: this.keyword,
        searchtype: this.searchtype,
        restype: this.restype,
      });
      this.loading = false;
      this.data = data;
      if (data && data.resources) {
        this.total = data.total;
        this.results = data.resources.map((item) => {
          item.res.fileType =
            item.res.isdir !== 1
              ? getFileType(`test.${item.res.ext}`)
              : "filetype_folder";
          return item;
        });
      } else if (
        (data &&
          data.resources === null &&
          data.total === 0 &&
          this.searchtype == undefined) ||
        this.searchtype == -1
      ) {
        this.$toast("正在切换至模糊搜索");
        this.$store.commit("changeFilterSearchType", 1);
        this.$router.push({
          path: "/main/search",
          query: {
            keyword: this.keyword,
            searchtype: 1,
            restype: this.restype,
          },
        });
      } else {
        this.results = [];
        this.total = 0;
      }
    },
    showLogin() {
      this.showLoginDialog();
    },
    goPayPage() {
      this.$router.push({ name: "pay" });
    },
    handleGetGift() {
      this.$toast.success("领取成功");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    changeSearchResType(type) {
      this.restype = type;
      this.$store.commit("changeFilterResType", this.restype);
      this.updatePage();
    },
    updatePage() {
      const query = {
        keyword: this.keyword,
      };
      if (this.size !== -1) {
        query.size = this.size;
      }
      if (this.time !== -1) {
        query.time = this.time;
      }
      if (this.type !== -1) {
        query.type = this.type;
      }
      if (this.searchtype !== -1) {
        query.searchtype = this.searchtype;
      }
      if (this.restype !== -1) {
        query.restype = this.restype;
      }
      this.$router.push({ path: "search", query });
      this.$store.commit("hideFilter");
    },
    async handleApplyUnblock() {
      await applyUnblock();
      this.$toast("申请成功，等待管理员处理");
    },
  },
  components: {
    FilterComponent,
    ResourceItem,
    Pager,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.result-page {
  background-color: #fff;
  border-radius: 4px;
  padding: 30px;
  min-height: 400px;
}

.search-result {
  .result-inner {
    position: relative;
  }
  .sensitive {
    text-align: center;
    .none-image {
      margin-top: 30px;
      width: 150px;
    }
    .none-tip {
      margin-top: 30px;
      font-size: 16px;
      margin-bottom: 10px;
      .em {
        color: $theme-color;
      }
    }
  }
  .search-tip {
    font-size: 14px;
    margin-bottom: 10px;
    .em {
      color: $theme-color;
      font-weight: bold;
    }
  }

  .loading-wrap {
    padding-top: 30px;
    text-align: center;
    .animation {
      height: 160px;
    }
  }
}

.search-tip-wrap {
  margin-top: 20px;
  line-height: 2;
  .search-tip {
    color: #666;
    &.em,
    .em {
      color: #333;
      font-weight: bold;
    }
  }
}

@media (max-width: 700px) {
  .result-page {
    padding: 15px;
  }
}
</style>
